@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import '~antd/dist/antd.css';
@import "./styles/antd.override.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app-layout {

  .ant-layout-sider {
    @apply bg-transparent;

    .ant-layout-sider-children {
      @apply bg-teal-400 font-bold;

      .sidenav-content {

        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub,
        .ant-menu.ant-menu-dark .ant-menu-sub {
          @apply bg-teal-400 text-white;
        }

        .ant-menu-dark .ant-menu-item,
        .ant-menu-dark .ant-menu-item-group-title,
        .ant-menu-dark .ant-menu-item>a,
        .ant-menu-dark .ant-menu-item>span>a {
          @apply text-white;
        }

        .ant-menu-submenu {

          &.ant-menu-submenu-active,
          &.ant-menu-submenu-open {
            .ant-menu-submenu-title {

              .ant-menu-submenu-expand-icon,
              .ant-menu-submenu-arrow {

                &::before,
                &::after {
                  @apply bg-teal-400;
                }
              }
            }
          }

          .ant-menu-submenu-title {

            .ant-menu-submenu-expand-icon,
            .ant-menu-submenu-arrow {

              &::before,
              &::after {
                @apply bg-white;
              }
            }
          }
        }

        .ant-menu-dark {

          .ant-menu-item:hover,
          .ant-menu-item-active,
          .ant-menu-submenu-active,
          .ant-menu-submenu-open,
          .ant-menu-submenu-selected,
          .ant-menu-submenu-title:hover {
            @apply bg-white text-teal-400;

            .ant-menu-submenu-expand-icon,
            .ant-menu-submenu-arrow {
              @apply text-teal-400;
            }
          }

          .ant-menu-item-selected>a,
          .ant-menu-item-selected>span>a {
            @apply text-white;
          }

          .ant-menu-item-selected .ant-menu-item-icon,
          .ant-menu-dark .ant-menu-item-selected .anticon {
            @apply text-teal-400;
          }

          .ant-menu-item:hover>a,
          .ant-menu-item-active>a,
          .ant-menu-submenu-active>a,
          .ant-menu-submenu-open>a,
          .ant-menu-submenu-selected>a,
          .ant-menu-submenu-title:hover>a,
          .ant-menu-item:hover>span>a,
          .ant-menu-item-active>span>a,
          .ant-menu-submenu-active>span>a,
          .ant-menu-submenu-open>span>a,
          .ant-menu-submenu-selected>span>a,
          .ant-menu-submenu-title:hover>span>a {
            @apply text-teal-400;
          }

          &.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
            @apply bg-white text-teal-400;

            >a,
            >span>a {
              @apply text-teal-400;
            }
          }
        }
      }
    }

    .ant-layout-sider-trigger {
      @apply bg-teal-400;
    }
  }
}

.site-layout .site-layout-background {
  @apply bg-white;
}