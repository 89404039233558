.ant-btn {

    &:hover,
    &:focus {
      @apply border-teal-400 border-opacity-90 text-teal-400;
    }
  
    &.ant-btn-dashed {
      @apply border border-solid border-teal-400 text-teal-400;
    }
  
    &.ant-btn-primary {
      @apply border-teal-400 bg-teal-400;
  
      &:hover,
      &:focus {
        @apply text-white border-teal-400 bg-teal-400 border-opacity-90 bg-opacity-90;
      }
    }
  
    &.ant-btn-link {
  
      &:hover,
      &:focus {
        @apply border-transparent text-teal-400 text-opacity-70;
      }
    }
  }
  
  
  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
    @apply text-teal-400;
  }
  
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
    @apply border-teal-400;
  }
  
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    @apply bg-teal-50 text-teal-400;
  }